import { memo, useCallback, useState } from "react"
import { HStack, Stack, Box, Text, Button, Spinner, Heading, useDisclosure } from "@chakra-ui/react"
import { useShopify } from "@app/hooks/useShopify"
import { useCore } from "@app/hooks/useCore"
import { CustomLink } from "@components/Link"
import { useDate } from "@app/hooks/useDate"
import { useStringHelpers } from "@app/hooks/useStringHelpers"
import { BrandedProductTitle } from "@app/components/BrandedProductTitle"
import { CustomerOrdersOutOfStockModal } from "./CustomerOrdersOutOfStockModal"
import { useCart } from "@app/hooks/useCart"
import { useCheckoutContext } from "@app/providers/checkout"
import { useAppContext } from "@app/providers/app"

const calculateAvailableLineItems = (lineItems: any[]) => {
  const availableLineItems: any[] = []
  const outOfStockLineItems: any[] = []

  lineItems?.forEach(lineItem => {
    if (
      !lineItem?.variant?.availableForSale &&
      (lineItem?.variant?.currentlyNotInStock || !lineItem?.variant?.quantityAvailable || lineItem?.variant?.quantityAvailable <= 0)
    ) {
      outOfStockLineItems.push({
        oosQty: lineItem?.quantity,
        totalQty: lineItem?.quantity,
        title: lineItem?.title,
      })
      return
    }

    if (!lineItem?.variant?.availableForSale && lineItem?.variant?.quantityAvailable < lineItem?.quantity) {
      const oosQty = lineItem?.quantity - lineItem?.variant?.quantityAvailable

      outOfStockLineItems.push({
        title: lineItem?.title,
        oosQty,
        totalQty: lineItem?.quantity,
      })

      availableLineItems.push({
        quantity: lineItem?.quantity - oosQty,
        variantId: lineItem?.variant?.id,
        customAttributes: lineItem?.customAttributes || [],
      })

      return
    }

    availableLineItems.push({
      quantity: lineItem?.quantity,
      variantId: lineItem?.variant?.id,
      customAttributes: lineItem?.customAttributes || [],
    })
    return
  })

  return { availableLineItems, outOfStockLineItems }
}

const CustomerOrdersItem = ({ order }: any) => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { capitalizeWord } = useStringHelpers()
  const [outOfStockInfo, setOutOfStockInfo] = useState([])
  const { isOpen: outOfStockModalIsOpen, onOpen: openOutOfStockModal, onClose: onOutOfStockModalClose } = useDisclosure()
  const { addToCartMultiple, loading: addToCartLoading } = useCart()

  const { formatMoney } = useShopify()
  const { getDayMonthLabel } = useDate()
  const { dayMonthLabel: orderDate } = getDayMonthLabel(order?.processedAt, "d LLL yyyy", false)
  const { checkout } = useCheckoutContext()
  const { state, dispatch } = useAppContext()

  const handleAddAllToCartClick = useCallback(() => {
    const calculateOosAndAddToCart = async () => {
      const isEmptyCart = checkout?.lineItems?.length === 0

      // Remove sample and gift products
      const filteredLineItems = order?.lineItems?.filter((lineItem: any) => {
        return !lineItem?.customAttributes?.map(
          (attribute: any) =>
            (attribute?.key === "isGift" && attribute?.value === "true") || (attribute?.key === "_isSample" && attribute?.value === "true")
        )?.length
      })

      const { availableLineItems, outOfStockLineItems } = calculateAvailableLineItems(filteredLineItems)

      if (outOfStockLineItems?.length) {
        setOutOfStockInfo(outOfStockLineItems)
      } else {
        setOutOfStockInfo([])
      }

      const mappedLineItems = availableLineItems?.map((lineItem: any) => ({
        quantity: lineItem?.quantity,
        customAttributes: lineItem?.customAttributes || [],
        variantId: lineItem?.variantId,
      }))

      if (mappedLineItems?.length) {
        await addToCartMultiple(mappedLineItems)

        if (isEmptyCart && !addToCartLoading) {
          dispatch({
            type: "cart",
            payload: !state.activeCart,
          })
        }
      }

      if (outOfStockLineItems?.length) {
        openOutOfStockModal()
      }
    }

    calculateOosAndAddToCart()
  }, [order, openOutOfStockModal, addToCartMultiple, addToCartLoading, checkout?.lineItems?.length, dispatch, state?.activeCart])

  if (!order?.id) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return (
    <Box key={order?.id?.toString()} bg="brand.offWhite" border="1.5px">
      <Stack direction={["column", "column", "column", "row"]} bg="brand.lightSand" py={3.5} px={6} justifyContent="space-between">
        <HStack>
          <CustomLink to={`/account/orders/${decodeShopifyId(order?.id, "Order")}`}>
            <Heading fontSize="xl">
              Order #{order?.orderNumber} -{" "}
              <Text as="span" fontSize="xl">
                {orderDate}
              </Text>
            </Heading>
          </CustomLink>
        </HStack>
        <Stack direction={["column", "column", "row"]}>
          <CustomLink to={order?.statusUrl} external styles={{ minW: [25, 49] }}>
            <Button variant="outline" size="md" w="full">
              Track Order
            </Button>
          </CustomLink>
          <Button variant="solid" onClick={handleAddAllToCartClick} size="md" isLoading={addToCartLoading}>
            Add All To Cart
          </Button>
        </Stack>
      </Stack>
      <Stack direction={["column", "column", "row"]} width="full" py={3.5} px={4} spacing={4}>
        <Box flex={1}>
          <Text fontWeight={700} fontSize="16px" lineHeight="24px">
            Shipped to
          </Text>
          <Text fontSize="14px" lineHeight="21px">
            {order?.shippingAddress?.address1}, {order?.shippingAddress?.province}
          </Text>
          <Box py={1} px={4} bg="brand.yellow" mt={4} w="fit-content" fontSize="xs" lineHeight="21px">
            {capitalizeWord(order?.fulfillmentStatus)}
          </Box>
        </Box>
        <Box flex={1}>
          <Text fontWeight={700} fontSize="16px" lineHeight="24px">
            Items
          </Text>
          {order?.lineItems?.map((item: any) => (
            <Box key={item?.id} sx={{ "*": { fontSize: "14px", lineHeight: "21px" } }} mb={2.5}>
              <Text>
                <BrandedProductTitle item={item?.variant?.product} showDash={false} />
              </Text>
              <Text>
                {item?.title} x {item?.quantity}
              </Text>
            </Box>
          ))}
        </Box>
        <Box flex={1}>
          <Text fontWeight={700} fontSize="16px" lineHeight="24px">
            Order Total
          </Text>
          <Text fontSize="14px" lineHeight="21px">
            {formatMoney(order?.totalPriceV2?.amount, order?.currencyCode)}
          </Text>
        </Box>
      </Stack>
      <CustomerOrdersOutOfStockModal outOfStockItems={outOfStockInfo} onClose={onOutOfStockModalClose} isOpen={outOfStockModalIsOpen} />
    </Box>
  )
}

const MemoCustomerOrdersItem = memo(CustomerOrdersItem)
export { MemoCustomerOrdersItem as CustomerOrdersItem }
